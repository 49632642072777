import _log from "utilise/log";
import _client from "utilise/client";
import _ready from "utilise/ready";
import _define from "@compone/define";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports = function components(ripple) {
  if (!client) return ripple;
  log("creating"); // if no render is defined on a component, load up definition

  Node.prototype.render = function () {
    const name = (this || _global).nodeName.toLowerCase();

    if (name.includes("-")) {
      (this || _global).state = (this || _global).state || {};
      return (this || _global).fn$ = (this || _global).fn$ || ripple.subscribe(name).map(component => define(name, component)); // TODO: test this works well across all instances
      // .until(new Promise(resolve => this.addEventListener('disconnected', () => {
      //   if (!this.isConnected) resolve()
      // })))
    }
  }; // this is for backwards compatibility


  Node.prototype.draw = function () {
    this.render();
  };

  ready(() => Array.from(document.querySelectorAll("*")).filter(d => d.nodeName.includes("-")).map(node => node.render()));
  return ripple;
};

const log = _log("[ri/components]"),
      client = _client,
      ready = _ready,
      define = _define;

export default exports;